<template>
  <!-- <el-dialog
    title="编辑企业"
    :visible="dialogVisible"
    @open="getEnterprise"
    @close="closeDialog"
  >-->
  <div style="background: #eceff5; padding: 20px">
    <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <div class="edit_border">
        <div class="edit_top">
          <el-card class="border_left">
            <div class="left_top">
              <div class="left_icon">
                <span></span>
                <span>基础信息</span>
              </div>
              <div class="right_icon">
                <span></span>
              </div>
            </div>
            <!-- <el-form-item label="营业执照" prop="idCardImage" class="item"> -->
            <div style="display: flex; width: 120px">
              <span style="color: red; margin-right: 3px">*</span>
              <span style="font-size: 14px">营业执照</span>
            </div>
            <div class="upload">
              <el-card class="example" body-style="padding:0">
                <span v-if="!idCardImage">+</span>
                <img :src="idCardImage" v-else alt />
              </el-card>
              <div class="upload_right">
                <p>示例图</p>
                <span>
                  请上传多合一营业执照原件图片或加盖公章复印件
                  支持.jpg.jpeg.png格式，大小不超过2M
                </span>
                <el-upload
                  accept=".png, .jpg, jpeg, JPG, JPEG"
                  :action="action"
                  :on-success="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :limit="1"
                  auto-upload
                  :on-exceed="Totag"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </div>
            </div>
            <el-form-item label="企业名称" prop="name" class="item" size="medium ">
              <el-input v-model="ruleForm.name" size="large"></el-input>
            </el-form-item>
            <el-form-item label="信用编码" prop="idCardNumber" class="item">
              <el-input v-model="ruleForm.idCardNumber" size="large"></el-input>
            </el-form-item>
            <el-form-item label="经营场所" prop="address" class="item">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>
          </el-card>
          <div class="border_right">
            <el-card class="right_one">
              <div class="left_top">
                <div class="left_icon">
                  <span></span>
                  <span>法人信息</span>
                </div>
                <div class="right_icon">
                  <span></span>
                </div>
              </div>
              <el-form-item label="法人姓名" prop="juridicalPersonName" style="margin: 30px 0">
                <el-input v-model="ruleForm.juridicalPersonName"></el-input>
              </el-form-item>

              <el-form-item label="法人身份证号" prop="juridicalPersonId" style="margin: 30px 0">
                <el-input v-model="ruleForm.juridicalPersonId"></el-input>
              </el-form-item>

              <div style="display: flex; width: 120px; margin: 20px 0">
                <span style="color: red; margin-right: 3px">*</span>
                <span style="font-size: 14px">法人身份证</span>
              </div>
              <el-row>
                <el-col :span="10">
                  <el-card
                    :body-style="{
                      padding: '0px',
                      width: '300px',
                      height: '200px'
                    }"
                    class="card"
                  >
                    <img
                      src="../../../assets/font.png"
                      alt
                      v-if="!juridicalPersonIdFont"
                      style="width: 100%; height: 80%"
                    />
                    <img v-else :src="juridicalPersonIdFont" style="width: 100%; height: 80%" />

                    <div class="bottom clearfix">
                      <el-upload
                        accept=".png, .jpg, jpeg, JPG, JPEG"
                        :action="action"
                        :on-success="handlejuridicalPersonIdFont"
                        :on-remove="handleRemove"
                        :limit="1"
                        auto-upload
                        :on-exceed="Totag"
                      >
                        <el-button type="text" class="button">请上传正面</el-button>
                      </el-upload>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="10" :offset="1">
                  <el-card
                    :body-style="{
                      padding: '0px',
                      width: '300px',
                      height: '200px'
                    }"
                  >
                    <img
                      src="../../../assets/bank.png"
                      style="width: 100%; height: 80%"
                      v-if="!juridicalPersonIdBack"
                    />
                    <img v-else :src="juridicalPersonIdBack" style="width: 100%; height: 80%" />
                    <div class="bottom clearfix">
                      <el-upload
                        :action="action"
                        :on-success="handlejuridicalPersonIdBack"
                        :on-remove="handleRemove"
                        :limit="1"
                        auto-upload
                        :on-exceed="Totag"
                        accept=".png, .jpg, jpeg, JPG, JPEG"
                      >
                        <el-button type="text" class="button">请上传反面</el-button>
                      </el-upload>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-card>

            <el-card class="right_one">
              <div class="left_top">
                <div class="left_icon">
                  <span></span>
                  <span>开户信息</span>
                </div>
                <div class="right_icon">
                  <span></span>
                </div>
              </div>
              <el-form-item label="银行" prop="bankName" style="margin: 30px 0">
                <el-input v-model="ruleForm.bankName"></el-input>
              </el-form-item>
              <el-form-item label="银行卡号" prop="bankCode" style="margin: 30px 0">
                <el-input v-model="ruleForm.bankCode"></el-input>
              </el-form-item>

              <el-form-item label="开户地" prop="bankArea" style="margin: 30px 0">
                <District v-model="ruleForm.bankArea" :level="level"></District>
              </el-form-item>

              <el-form-item label="支行" prop="bankBranch" style="margin: 30px 0">
                <el-input v-model="ruleForm.bankBranch"></el-input>
              </el-form-item>

              <el-form-item label="支行联行号" prop="bankBranchCode" style="margin: 30px 0">
                <el-input v-model="ruleForm.bankBranchCode"></el-input>
              </el-form-item>
            </el-card>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </div>
      </div>
    </el-form>
  </div>
  <!-- </el-dialog> -->
</template>
<script>
import moment from 'moment';
import { api } from '/src/api/base';
// import config from "/src/config/config";
const request = api('/enterprise');
import * as _ from 'lodash';
import District from '/src/components/district';
export default {
  components: {
    District
  },
  data () {
    return {
      code: '',
      idCardImage: '',
      juridicalPersonIdFont: '',
      juridicalPersonIdBack: '',
      ruleForm: {
        name: '',
        idCardNumber: '',
        address: '',

        juridicalPersonName: '',
        juridicalPersonId: '',

        bankName: '',
        bankCode: '',
        bankArea: '',
        bankBranch: '',
        bankBranchCode: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        idCardNumber: [
          { required: true, message: '请输入统一信用编码', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入统一信用编码', trigger: 'blur' }
        ],
        // idCardImage: [
        //   { required: true, message: "请上传营业执照照片", trigger: "blur" }
        // ],

        juridicalPersonName: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' }
        ],
        juridicalPersonId: [
          { required: true, message: '请输入法人身份证号', trigger: 'blur' }
        ],
        // juridicalPersonIdFont: [
        //   {
        //     required: true,
        //     message: "请上传法人身份证正面照片",
        //     trigger: "blur"
        //   }
        // ],
        // juridicalPersonIdBack: [
        //   {
        //     required: true,
        //     message: "请上传法人身份证反面照片",
        //     trigger: "blur"
        //   }
        // ],

        bankName: [{ required: true, message: '请输入银行', trigger: 'blur' }],

        bankCode: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ],

        bankArea: [
          { required: true, message: '请输入开户地', trigger: 'blur' }
        ],

        bankBranch: [
          { required: true, message: '请输入支行', trigger: 'blur' }
        ],

        bankBranchCode: [
          { required: true, message: '请输入支行联行号', trigger: 'blur' }
        ]
      },
      dialogVisible: false,

      action: '',
      path: '/enterprise.upload.json',
      level: 'city'
    };
  },
  // props: {
  //   dialogVisible: Boolean,
  //   dialogData: String
  // },
  mounted () {
    this.code = this.$route.query.code;
    this.getEnterprise();
    this.action = `${window.location.origin}${this.path}`;
  },
  methods: {
    getEnterprise () {
      request('enterprise.describe.json')({ code: this.code }).then(res => {
        Object.keys(this.ruleForm).map(it => {
          this.ruleForm[it] = res[it];
        });
        this.idCardImage = res.idCardImage;
        this.juridicalPersonIdFont = res.juridicalPersonIdFont;
        this.juridicalPersonIdBack = res.juridicalPersonIdBack;
        console.log(this.juridicalPersonIdFont, 1111111);
      });
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {};
          Object.keys(this.ruleForm).forEach(key => {
            const it = this.ruleForm[key];
            if (_.isDate(it)) {
              params[key] = moment(it).format('YYYY-MM-DD HH:mm');
              return;
            }
            if (_.isArray(it)) {
              params[key] = it.join(',');
              return;
            }
            params[key] = it;
          });
          if (!this.idCardImage) {
            this.$message({
              message: '请上传营业执照',
              type: 'warning'
            });
          } else if (!this.juridicalPersonIdFont) {
            this.$message({
              message: '请上传法人身份证正面',
              type: 'warning'
            });
          } else if (!this.juridicalPersonIdBack) {
            this.$message({
              message: '请上传法人身份证反面',
              type: 'warning'
            });
          } else {
            request('enterprise.edit.json')({
              ...params,
              code: this.code,
              idCardImage: this.idCardImage,
              juridicalPersonIdFont: this.juridicalPersonIdFont,
              juridicalPersonIdBack: this.juridicalPersonIdBack
            }).then(res => {
              console.log(res);
              window.history.go(-1);
              // this.closeDialog();
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      console.log(this.code);
      this.$refs[formName].resetFields();
    },
    Totag () {
      this.$message({
        message: '警告哦，只能上传一张,只有删除上一张才能替换',
        type: 'warning'
      });
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlejuridicalPersonIdFont (file) {
      this.juridicalPersonIdFont = file.data;
      console.log(this.juridicalPersonIdFont, '正面');
    },
    handlejuridicalPersonIdBack (file) {
      this.juridicalPersonIdBack = file.data;
      console.log(this.juridicalPersonIdBack, '反面');
    },
    handlePictureCardPreview (file) {
      this.idCardImage = file.data;
      console.log(this.idCardImage);
    },
    closeDialog (formName) {
      this.idCardImage = '';
      this.juridicalPersonIdFont = '';
      this.juridicalPersonIdBack = '';
      this.resetForm(formName);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.edit_border {
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  width: 100%;
  .edit_top {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    padding: 20px;
    width: 100%;
    .border_left {
      width: 48%;

      .left_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        .left_icon {
          display: flex;
          align-items: center;
          span:nth-child(1) {
            padding: 8px 4px;
            background: #4977fc;
            margin-right: 10px;
          }
          span:nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .right_icon {
          width: 18px;
          height: 18px;
          background: #4977fc;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          span:nth-child(1) {
            display: block;
            width: 10px;
            height: 10px;
            background: #4977fc;
            border-radius: 50%;
            background: #fff;
            border-radius: 50%;
          }
        }
      }
    }
    .border_right {
      width: 48%;
      display: flex;
      flex-wrap: wrap;

      border-radius: 10px;
      .right_one {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        .left_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          .left_icon {
            display: flex;
            align-items: center;
            span:nth-child(1) {
              padding: 8px 4px;
              background: #4977fc;
              margin-right: 10px;
            }
            span:nth-child(2) {
              font-size: 16px;
              font-weight: 700;
            }
          }
          .right_icon {
            width: 18px;
            height: 18px;
            background: #4977fc;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            span:nth-child(1) {
              display: block;
              width: 10px;
              height: 10px;
              background: #4977fc;
              border-radius: 50%;
              background: #fff;
              border-radius: 50%;
            }
          }
        }
      }
      .right_one:nth-child(2) {
        margin: 20px 0 0 0;
      }
    }
  }
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload {
  padding: 20px 120px;
  display: flex;
  align-items: center;
  .example {
    width: 260px;
    height: 180px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 30px;
      color: #4977fc;
      border-radius: 50%;
      // border: 1px solid #4977fc;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .upload_right {
    width: 316px;
    margin: 20px 0 0 23px;
    text-align: left;
    p {
      font-size: 14px;
      margin: 0;
      color: #888888;
    }
    span {
      font-size: 12px;
      color: #e1e1e1;
      margin: 10px 0;
      display: block;
    }
  }
}

.clearfix {
  background: #4977fc;
  color: #fff;
  button {
    width: 100%;
    color: #fff;
  }
}
.dialog-footer {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
